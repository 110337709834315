import { Box, Image } from "@chakra-ui/react";
import { InternalLink } from "components/atoms/InternalLink";
import { route } from "config/routes";
import { useNavigationContext } from "./Navigation";

export const NavigationLogo = () => {
  const { isScrolled, scrollOffset } = useNavigationContext();

  return (
    <InternalLink href={route("HOMEPAGE_INDEX")}>
      <Box w={{ base: isScrolled ? 120 : 160, lg: 160 }} transition="all .15s">
        <Image src="/images/logo/main.png" />
      </Box>
    </InternalLink>
  );
};
